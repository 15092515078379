// import React from "react";
// import Navbar from "../Components/Footer";
// import Footer from "../Components/Navbar";
// import certificateModule from "../Styles/Certificates.module.css";

// const CERTIFICATES = ({ CERTIFICATES }) => {
//   return (
//     <div className="cert-container" style={{ height: "800px" }}>
//       <h1
//         style={{
//           textAlign: "center",
//           color: "#0e3689",
//           fontWeight: "bold",
//           marginTop: "20px",
//           marginBottom: "20px",
//         }}
//       >
//         Certificates
//       </h1>
//       <p style={{ textAlign: "center" }}>
//         <strong>
//           This is to certify that the mechanical products supplied by Iconic
//           Components have undergone rigorous quality control measures to ensure
//           excellence in performance and reliability. Our commitment to
//           delivering high-precision components adheres to the highest industry
//           standards.
//         </strong>
//       </p>
//       <br />
//       <div class="container mt-3">
//         <div class="row">
//           <div class="col-md-4 col-sm-2 item">
//             <div class="card item-card card-block">
//               <img
//                 src="https://isoindia.org/admin/download_files/sample%20of%2031000.jpg"
//                 alt="Photo of sunset"
//               />
//             </div>
//           </div>
//           <div class="col-md-4 col-sm-2 item">
//             <div class="card item-card card-block">
//               <img
//                 src="https://image.slidesharecdn.com/bf945567-9509-4e81-b5d1-147a14250526-160520085331/75/ssi-certificate-1-2048.jpg?cb=1674110301"
//                 alt="Photo of sunset"
//               />
//             </div>
//           </div>
//           <div class="col-md-4 col-sm- item">
//             <div class="card item-card card-block">
//               <img
//                 src="https://isoindia.org/admin/download_files/sample%20of%2031000.jpg"
//                 alt="Photo of sunset"
//               />
//             </div>
//           </div>
//         </div>
//       </div>
//     </div>
//   );
// };

// export default CERTIFICATES;
import React from "react";
import Navbar from "../Components/Footer";
import Footer from "../Components/Navbar";
import certificateModule from "../Styles/Certificates.module.css";

const CERTIFICATES = ({ CERTIFICATES }) => {
  return (
    <div className="cert-container" style={{ padding: "20px 0", textAlign: "center" }}>
      <h1 style={{ color: "#0e3689", fontWeight: "bold", marginBottom: "20px" }}>
        Certificates
      </h1>
      <p>
        <strong>
          This is to certify that the mechanical products supplied by Iconic
          Components have undergone rigorous quality control measures to ensure
          excellence in performance and reliability. Our commitment to
          delivering high-precision components adheres to the highest industry
          standards.
        </strong>
      </p>
      <br />
      <div className="container">
        <div className="row">
          <div className="col-md-4 col-sm-12 mb-3">
            <div className="card item-card card-block">
              <img
                src="https://isoindia.org/admin/download_files/sample%20of%2031000.jpg"
                alt="Certificate 1"
                className="img-fluid"
              />
            </div>
          </div>
          <div className="col-md-4 col-sm-12 mb-3">
            <div className="card item-card card-block">
              <img
                src="https://image.slidesharecdn.com/bf945567-9509-4e81-b5d1-147a14250526-160520085331/75/ssi-certificate-1-2048.jpg?cb=1674110301"
                alt="Certificate 2"
                className="img-fluid"
              />
            </div>
          </div>
          <div className="col-md-4 col-sm-12 mb-3">
            <div className="card item-card card-block">
              <img
                src="https://isoindia.org/admin/download_files/sample%20of%2031000.jpg"
                alt="Certificate 3"
                className="img-fluid"
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default CERTIFICATES;
