import React, { useContext, useState } from "react";
import axios from "axios";
import { useNavigate } from "react-router-dom";
import { AuthContext } from "../Context/AuthContext";
import { ColorContext } from "../Context/ColorContext";
import {
  Button,
  Checkbox,
  Flex,
  Text,
  FormControl,
  FormLabel,
  Heading,
  Input,
  Stack,
  Image,
  Box,
} from "@chakra-ui/react";

const obj = {
  email: "",
  password: "",
};

function Login() {
  const { ChangeColor } = useContext(ColorContext);
  const [form, setForm] = useState(obj);
  const { setToken } = useContext(AuthContext);
  const navigate = useNavigate();

  const handleChange = (e) => {
    const { name, value } = e.target;
    setForm({ ...form, [name]: value });
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    if (!form.email || !form.password) {
      alert("Please fill in both email and password");
    } else {
      axios
        .post("https://iconic-component.onrender.com/auth/login", form)
        .then((res) => {
          alert(res.data.message);
          console.log(res.data);

          localStorage.setItem(
            "token",
            JSON.stringify({
              token: res.data.token,
              role: res.data.role,
              userId: res.data.userId,
            })
          );

          setToken({ token: res.data.token, role: res.data.role });

          ChangeColor();
          if (res.data.role === "admin") {
            navigate("/admin");
          } else {
            navigate("/");
          }
        })
        .catch((err) => console.log(err));
    }
  };

  return (
    <Box
      style={{
        padding: "70px",
        boxShadow: "rgba(0, 0, 0, 0.35) 0px 5px 15px",
      }}
    >
      <Stack
        minH={"80vh"}
        minW={"80vh"}
        direction={{ base: "column", md: "row" }}
      >
        <Flex p={8} flex={1} align={"center"} justify={"center"}>
          <Stack spacing={4} w={"full"} maxW={"md"}>
            <Heading fontSize={"2xl"}>Sign in to your account</Heading>
            <FormControl id="email">
              <FormLabel>Email address</FormLabel>
              <Input
                type="email"
                value={form.email}
                onChange={(e) => handleChange(e)}
                placeholder="Enter your email"
                name="email"
              />
            </FormControl>
            <FormControl id="password">
              <FormLabel>Password</FormLabel>
              <Input
                type="password"
                value={form.password}
                onChange={(e) => handleChange(e)}
                placeholder="Enter your password"
                name="password"
              />
            </FormControl>
            <Stack spacing={6}>
              <Stack
                direction={{ base: "column", sm: "row" }}
                align={"start"}
                justify={"space-between"}
              >
                <Checkbox>Remember me</Checkbox>
                <Text color={"blue.500"}>Forgot password?</Text>
              </Stack>
              <Button
                colorScheme={"blue"}
                variant={"solid"}
                onClick={handleSubmit}
              >
                Sign in
              </Button>
            </Stack>
          </Stack>
        </Flex>
        <Flex flex={1}>
          <Image
            alt={"Login Image"}
            objectFit={"cover"}
            src={
              "https://images.unsplash.com/photo-1486312338219-ce68d2c6f44d?ixid=MXwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHw%3D&ixlib=rb-1.2.1&auto=format&fit=crop&w=1352&q=80"
            }
          />
        </Flex>
      </Stack>
    </Box>
  );
}

export default Login;
