import React, { useState, useEffect } from "react";
import axios from "axios";
import { useNavigate, useParams } from "react-router-dom";
import { Box, Text, Image } from "@chakra-ui/react";
import Styles from "../Styles/Product.module.css";

function SingleProduct() {
  const navigate = useNavigate();
  const params = useParams();
  const [data, setData] = useState({});

  async function fetchData() {
    try {
      const { id } = params;
      const response = await axios.get(
        `https://iconic-component.onrender.com/product/${id}`
      );
      setData(response.data);
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  }

  useEffect(() => {
    fetchData();
  }, [data]);

  return (
    <>
      <Box
        style={{
          height: "auto",
          width: "auto",

          padding: "30px",
          borderRadius: "20px",
          margin: "30px",
          boxShadow: "rgba(0, 0, 0, 0.35) 0px 5px 15px",
        }}
      >
        <Box>
          <Image
            src={data.img}
            style={{
              height: "400px",
              width: "600px",
              borderRadius: "10px",
            }}
          />
        </Box>

        <Box style={{ padding: "5px" }}>
          <Text>
            <span
              style={{
                fontSize: "40px",
                marginLeft: "20px",
                fontWeight: "bold",
              }}
            >
              {data.name}
            </span>
          </Text>
          <Text></Text>
          <Text>
            <span className="singleData_product"> Product Application :</span>
            {data.application}
          </Text>

          <Text>
            <span className="singleData_product"> Product Range :</span>
            {data.production_range}
          </Text>
          <Text>
            <span className="singleData_product"> Product Subcategory :</span>
            {data.subCategory}
          </Text>

          <Text>
            <span className="singleData_product">
              Product manufacture place :
            </span>
            {data.manufacture_place}
          </Text>

          <Text>
            <span className="singleData_product"> Raw material grades :</span>
            {data.raw_material_grades}
          </Text>

          <Text>
            <span className="singleData_product"> Product Description :</span>
            {data.description}
          </Text>

          <div>
            <span className="singleData_product">
              Product Sub-Category Images :
            </span>
            <div className="image-row">
              {data.sub_Category_images &&
                data.sub_Category_images.map((image, index) => (
                  <span className="img_data" key={index}>
                    {image && <Image src={image} />}
                  </span>
                ))}
            </div>
          </div>
        </Box>
      </Box>
    </>
  );
}

export default SingleProduct;
