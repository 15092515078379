import React, { useEffect, useState } from "react";

const GallerySection = () => {
  const [logos, setLogos] = useState([]);
  useEffect(() => {
    // You can add more logos/images to the array as needed
    const logos = [
      "https://eibach.com.au/wp-content/uploads/2012/09/valve-header.jpg",
      "https://www.fictiv.com/wp-content/uploads/2017/03/springs-image01.png",
      "https://4.imimg.com/data4/KP/VE/MY-16446935/electronic-spring-500x500.jpg",
      "https://img1.exportersindia.com/product_images/bc-full/2021/5/8663902/electrical-springs-1620795238-5818928.jpeg",
      "https://5.imimg.com/data5/OA/TX/MY-405060/heavy-duty-coil-springs-500x500.jpg",
      "https://img1.exportersindia.com/product_images/bc-full/2021/5/8663902/electrical-springs-1620795238-5818928.jpeg",
      "https://d3hvs2gyy8n2xz.cloudfront.net/blog/wp-content/uploads/2016/08/10131031/types-of-springs-for-your-vehicle.jpg",
      "https://5.imimg.com/data5/FG/VO/MC/SELLER-59937254/snap-hooks-with-eyelets-.jpg",
      "https://images2.imgbox.com/9f/d6/XIOlmIFq_o.png",
      "https://www.vulcanspring.com/wp-content/uploads/2019/02/spiral-torsion-springs-and-flat-wire-forms.jpg",
    ];

    // Dynamically create image elements and append them to the ticker
    const tickerContent = document.getElementById("ticker-content");
    logos.forEach((logo) => {
      const img = document.createElement("img");
      img.src = logo;
      img.alt = "Company Logo";
      tickerContent.appendChild(img);
    });

    // Clean up the created elements on component unmount
    return () => {
      logos.map((logo) => {
        const img = document.querySelector(`img[src="${logo}"]`);
        if (img) {
          tickerContent.removeChild(img);
        }
      });
    };
  }, []);

  return (
    <section className="doctor_section layout_padding" style={{paddingLeft:"20px"}}>
      <div className="container">
        <div
          className="heading_container heading_center"
          style={{ paddingBottom: "30px" }}
        >
          <h1
            style={{
              marginTop: "20px",
              textAlign: "center",
              color: "white",
              fontWeight: "bold",
            }}
          >
            Gallery
          </h1>
        </div>
        <div id="ticker-container">
          <div
            id="ticker-content"
            style={{
              // border: "2px solid red",
              display: "flex",
              height: "200px",
              width: "200px",
            }}
          >
            {/* Render images dynamically */}
            {logos.map((logo, index) => (
              <img key={index} src={logo} alt={`logo${index + 1}`} />
            ))}
          </div>
        </div>
      </div>
    </section>
  );
};

export default GallerySection;
