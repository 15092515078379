import React from "react";

const About = () => {
  return (
    <div className="container">
      <div className="heading_container heading_center">
        <h1
          style={{
            margin: "40px",
            marginTop: "20px",
            textAlign: "center",
            color: "#0e3689;",
            fontWeight: "bold",
          }}
        >
          About Us
        </h1>
      </div>
      <div className="row">
        <div className="col-md-6">
          <div className="img-box">
            <img
              style={{ height: "300px", width: "600px" }}
              src="https://img.freepik.com/free-photo/conference-room-with-desk-wall-windows-that-says-office_1340-37385.jpg?size=626&ext=jpg&ga=GA1.1.283669752.1695365255&semt=ais"
              alt="iconic_office"
            />
            <br />
            <ul>
              <li className="margin_25px">
                Iconic Components is managed by Mr. Keerthi C.R who has industry
                experience of more than seven years. Iconic Components was
                established in the year 2019 & we are ISO 9001-2015 Certified.
              </li>
              <br />
              {/* <li className="margin_25px">
                Iconic Components is managed by Mr. Keerthi C.R who has industry
                experience of more than seven years. Iconic Components was
                established in the year 2019 & we are ISO 9001-2015 Certified.
              </li> */}
              <li className="margin_25px">
                We manufacture each & every form of spring for the different
                sector industries such as Automobile, Aerospace,
                Medical/Pharmaceutical, Electrical & Electronic, Agro based
                Industry, Construction equipment’s, Textile Machinery, SPM,
                Industrial Equipment’s and many more.
              </li>
              <br />
              <li className="margin_25px">
                We manufacture a different types of customized springs, wire
                forms and press components
              </li>
              <br />
              <li className="margin_25px">
                Quality Policy For Continual improvement in Manufacture of
                Springs and Sheet Metal parts for Automobile, Electrical,
                Electronic, Engineering, and Civil Industry.
              </li>
              <br />
              <li className="margin_25px">
                We are Manufacturer of Springs for Automobile, Engineering,
                Civil, Power Energy & Pharmaceutical Industries.
              </li>
            </ul>
          </div>
        </div>
        <div className="col-md-6">
          <div className="detail-box">
            <div className="heading_container">
              <h2
                id="about_us"
                style={{
                  scrollBehavior: "smooth",
                }}
              >
                {/* About <span>Us</span> */}
              </h2>
            </div>
            <p>
              <ul>
                <li className="margin_25px">
                  We work on well organized machines as well as we work on "CNC
                  Machines" to provide better quality product to fulfil the need
                  of our client.
                </li>
                <br />
                <li className="margin_25px">
                  We have years of experience in this field and well organized
                  manufacturing plant & office with proper certifications. From
                  many years, we are a successful supplier to our many clients
                  in India.
                </li>
                <br />
                <li className="margin_25px">
                  To be the key spring solution provider to our valuable
                  customers all over the world. To fulfill the clients
                  requirement with our quality and services. - To create our
                  presence in global market.
                </li>
                <br />
                <li className="margin_25px">
                  Iconic Components prioritizes quality in its manufacturing
                  processes. Our infrastructure is well-equipped with the
                  necessary machinery and regularly updated to maintain
                  competitiveness. The facility is organized into efficient
                  units, enhancing overall productivity.
                </li>
                <br />
                <li className="margin_25px">
                  We emphasize the use of high-grade raw materials and modern
                  machinery, aligning with industry standards. This ensures our
                  products exhibit superior qualities such as sturdiness,
                  durability, dimensional accuracy, and corrosion resistance.
                  Rigorous quality examinations are conducted to eliminate
                  manufacturing defects with precision.
                </li>
                <br />
              </ul>
              <div className="img-box">
                <img
                  style={{ height: "300px", width: "600px" }}
                  src="https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcQRL18A-_8AnzT6032VEhO4bwpOvhJh3_9Mhg&usqp=CAU"
                  alt="Sidvin Hospital Image"
                />
              </div>
              {/* <div className="img-box" style={{ marginLeft: "35px" }}>
                <iframe
                  width="600"
                  height="300"
                  src="https://www.youtube.com/embed/GBO79ctIHzs"
                  frameborder="0"
                  allowfullscreen
                ></iframe>
              </div> */}
            </p>
            {/* <a href="">Read More</a> */}
          </div>
        </div>
      </div>
    </div>
  );
};

export default About;
