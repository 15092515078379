import React, { useState } from "react";
import {
  Container,
  Box,
  Heading,
  Text,
  Button,
  VStack,
  FormControl,
  FormLabel,
  Input,
  InputGroup,
  InputLeftElement,
  Textarea,
} from "@chakra-ui/react";
import { MdPhone, MdEmail, MdLocationOn } from "react-icons/md";
import axios from "axios";
import { useNavigate } from "react-router-dom";
import { useRef } from "react";
import emailjs from "@emailjs/browser";

function Contact() {
  const navigate = useNavigate();
  const form = useRef();

  const [formData, setFormData] = useState({
    name: "",
    email: "",
    contact_number: "",
    product_name: "",
    company_name: "",
    message: "",
  });

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormData({
      ...formData,
      [name]: value,
    });
  };
  async function getData() {
    let res = await axios.get(`https://iconic-component.onrender.com/contact`);
    console.log(res.data, "dataaaaaaaaaaaaaaaaaaaaa");
    setFormData(res.data);
  }

  const handleSubmit = (e) => {
    e.preventDefault();
    console.log(formData);
    axios
      .post(`https://iconic-component.onrender.com/contact`, formData)
      .then((res) => {
        alert("contact added sucessfully");
        getData();
        navigate("/");
      });
  };

  const sendEmail = (e) => {
    e.preventDefault();

    emailjs
      .sendForm(
        "service_jqhbq3n",
        "template_a0lqcr7",
        form.current,
        "tZdipczSkWKr-socx"
      )
      .then(
        (result) => {
          console.log(result.text);
        },
        (error) => {
          console.log(error.text);
        }
      );
  };

  return (
    <div className="form-main">
      <div className="main-wrapper" style={{ margin: "20px" }}>
        <h2 className="form-head">Contact From</h2>
        <form ref={form} onSubmit={sendEmail} className="form-wrapper">
          <div className="form-card">
            <FormControl id="full_name">
              <FormLabel>Full Name</FormLabel>
              <InputGroup borderColor="#E0E1E7">
                <Input
                  className="form-input"
                  type="text"
                  name="name"
                  required
                  onChange={handleInputChange}
                  value={formData.name}
                />
              </InputGroup>
            </FormControl>
          </div>

          <div className="form-card">
            <FormControl id="email">
              <FormLabel>Email</FormLabel>
              <InputGroup borderColor="#E0E1E7">
                <InputLeftElement
                  pointerEvents="none"
                  // children={<MdEmail color="gray.800" />}
                />
                <Input
                  type="email"
                  name="email"
                  size="md"
                  onChange={handleInputChange}
                  value={formData.email}
                />
              </InputGroup>
            </FormControl>
          </div>

          <div className="form-card">
            <FormControl id="phone_number">
              <FormLabel>Phone Number</FormLabel>
              <InputGroup borderColor="#E0E1E7">
                <InputLeftElement
                  pointerEvents="none"
                  // children={<MdPhone color="gray.800" />}
                />
                <Input
                  type="text"
                  name="contact_number"
                  size="md"
                  onChange={handleInputChange}
                  value={formData.contact_number}
                />
              </InputGroup>
            </FormControl>
          </div>
          <div className="form-card">
            <FormControl id="company_name">
              <FormLabel>Company Name</FormLabel>
              <InputGroup borderColor="#E0E1E7">
                <InputLeftElement
                  pointerEvents="none"
                  // children={<MdEmail color="gray.800" />}
                />
                <Input
                  type="text"
                  name="company_name"
                  size="md"
                  onChange={handleInputChange}
                  value={formData.company_name}
                />
              </InputGroup>
            </FormControl>
          </div>
          <div className="form-card">
            <FormControl id="product_name">
              <FormLabel>Product Name</FormLabel>
              <InputGroup borderColor="#E0E1E7">
                <InputLeftElement
                  pointerEvents="none"
                  // children={<MdEmail color="gray.800" />}
                />
                <Input
                  type="text"
                  name="product_name"
                  size="md"
                  onChange={handleInputChange}
                  value={formData.product_name}
                />
              </InputGroup>
            </FormControl>
          </div>

          <div className="form-card">
            <FormControl id="message">
              <FormLabel>Message</FormLabel>
              <Textarea
                className="form-textarea"
                maxLength="420"
                rows="3"
                name="message"
                required
                onChange={handleInputChange}
                value={formData.message}
              />
            </FormControl>
          </div>

          <div className="btn-wrap">
            <Button
              variant="solid"
              bg="#0D74FF"
              color="white"
              _hover={{}}
              onClick={handleSubmit}
            >
              Submit
            </Button>
          </div>
        </form>
      </div>
    </div>
  );
};
    
export default Contact;
