import React, { useState, useEffect } from "react";
import axios from "axios";
import Styles from "../Styles/Product.module.css";
import { useNavigate } from "react-router-dom";
import { useLocation } from "react-router-dom";
import { useParams } from "react-router-dom";

function Product() {
  const [data, setData] = useState([]);
  const [fdata, setfData] = useState([]);
  const [categories, setCategories] = useState([]);
  const [selectedCategory, setSelectedCategory] = useState("");
  const [currentPage, setCurrentPage] = useState(1);
  const [selectedProduct, setSelectedProduct] = useState("");
  const productsPerPage = 16;
  const param = useParams();
  // Use the useLocation hook to get the current location object
  const location = useLocation();

  // Parse the search property to get the query parameters
  const queryParams = new URLSearchParams(location.search);

  // Access individual query parameters
  const paramValue = queryParams.get("filter");

  const navigate = useNavigate();
  async function getData() {
    try {
      let res = await axios.get(
        "https://iconic-component.onrender.com/product"
      );
      // console.log(res.data, "pppppppppppppppppppppppp");
      setData(res.data);
      setfData(res.data);

      const uniqueCategories = [
        ...new Set(res.data.map((item) => item.category)),
      ];
      setCategories(uniqueCategories);
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  }
  function handleFilter(e) {
    const value = e.target.value;
    setSelectedCategory(value);
    setSelectedProduct(""); // Reset selected product

    if (value === "") {
      setfData(data);
    } else {
      const filterArr = data.filter((e) => e.category === value);
      setfData(filterArr);
      setCurrentPage(1);
    }
  }

  function handleProductChange(e) {
    const value = e.target.value;
    setSelectedProduct(value);

    if (value === "") {
      // Reset to display all products in the selected category
      const filterArr = data.filter((e) => e.category === selectedCategory);
      setfData(filterArr);
      setCurrentPage(1);
    } else {
      // Filter products based on the selected product name
      const filterArr = data.filter(
        (e) => e.category === selectedCategory && e.name === value
      );
      setfData(filterArr);
      setCurrentPage(1);
    }
  }
  const indexOfLastProduct = currentPage * productsPerPage;
  const indexOfFirstProduct = indexOfLastProduct - productsPerPage;
  const currentProducts = fdata.slice(indexOfFirstProduct, indexOfLastProduct);
  const totalPages = Math.ceil(fdata.length / productsPerPage);

  useEffect(() => {
    if (!paramValue) {
      setfData(data);
      console.log(paramValue, "sssssssssssssss");
    } else {
      const filterArr = data.filter((e) => e.category === paramValue);
      setfData(filterArr);
      setCurrentPage(1);
    }
  }, [paramValue]);

  useEffect(() => {
    getData();
  }, []);
  return (
    <div>
      <div
        id="filterSort"
        style={{
          display: "flex",
          justifyContent: "space-around",
          columnGap: "30px",
          width: "auto",
          // border: "2px solid red",
          // background: "yellow",
          justifyItems: "center",
          // alignItems: "center",
        }}
      >
        {/* <select
          style={{
            marginTop: "20px",
            width: "auto",
            boxShadow: "rgba(0, 0, 0, 0.35) 0px 5px 15px",
          }}
          id="filter"
          name="category"
          value={selectedCategory}
          onChange={(e) => handleFilter(e)}
        >
          <option value="">Category</option>
          {categories.map((category, index) => (
            <option key={index} value={category}>
              {category}
            </option>
          ))}
        </select> */}

        {/* {selectedCategory && (
          <select
            style={{
              marginTop: "20px",
              width: "300px",
              boxShadow: "rgba(0, 0, 0, 0.35) 0px 5px 15px",
            }}
            id="product"
            value={selectedProduct}
            onChange={(e) => handleProductChange(e)}
          >
            <option value="">Select Product</option>
            {data
              .filter((item) => item.category === selectedCategory)
              .map((product, index) => (
                <option key={index} value={product.name}>
                  {product.name}
                </option>
              ))}
          </select>
        )} */}
      </div>

      <div id={Styles.container}>
        {currentProducts.map((e, i) => {
          return (
            <div id={Styles.box} key={i}>
              <img src={e.img} alt="prodcut_img" />
              <h5>{e.name}</h5>
              <h6>{e.category}</h6>
              <div
                to={`/singleproduct/${e._id}`}
                className="btn btn-outline-primary"
                onClick={() => {
                  navigate(`/singleproduct/${e._id}`);
                }}
              >
                Details
              </div>
            </div>
          );
        })}
      </div>

      {/* pagination function  */}
      {/* <div style={{ textAlign: "center" }}>
        {Array.from({ length: totalPages }, (_, index) => (
          <button
            key={index}
            onClick={() => setCurrentPage(index + 1)}
            style={{
              backgroundColor:
                currentPage === index + 1 ? "blue" : "transparent",
              color: currentPage === index + 1 ? "white" : "black",
              border: "1px solid #ccc",
              padding: "5px 10px",
              margin: "5px",
              cursor: "pointer",
            }}
          >
            {index + 1}
          </button>
        ))}
      </div> */}
    </div>
  );
}

export default Product;