import React, { useEffect, useState } from "react";

const TestimonialSection = () => {
  const [activeIndex, setActiveIndex] = useState(0);

  useEffect(() => {
    const intervalId = setInterval(() => {
      handleNext();
    }, 3000); // Adjust the interval as needed (in milliseconds)

    // Clear the interval on component unmount
    return () => clearInterval(intervalId);
  }, [activeIndex]);

  const handleNext = () => {
    const newIndex = (activeIndex + 1) % testimonials.length;
    setActiveIndex(newIndex);
  };

  const handlePrev = () => {
    const newIndex =
      (activeIndex - 1 + testimonials.length) % testimonials.length;
    setActiveIndex(newIndex);
  };

  const testimonials = [
    {
      name: "Praksh Kumar",
      image:
        "https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcTDJzEaxLN-jGRYYUO65pWu7Q9GXoNt4LUSSA&usqp=CAU",
      text: "Choosing Iconic Components as our spring manufacturer was a decision that positively impacted our product quality. Their commitment to delivering top-notch springs for our electrical and electronic applications has made them an integral part of our supply chain.",
    },
    {
      name: "kavya mohali",
      image:
        "https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcRSkJNAphOxtsKdUIkAGA3gt9nTKz0H_rDJfWxFOkf7X2C9q9ySUbmEMyzrnAvCisVyY4Q&usqp=CAU",
      text: "Iconic Components is synonymous with reliability and innovation. As a manufacturer of springs for the pharmaceutical industry, we appreciate their responsiveness and ability to meet our unique requirements. Their products play a vital role in ensuring the efficiency of our operations.",
    },
    {
      name: "Pooja Patnayk",
      image:
        "https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcQ5PbSeqgQ-imt2QjafF8uRoyCvPHukQhpu9Z_0cP78FpohHJcs09IXtdtQgls42tSFtag&usqp=CAU",
      text: "Iconic Components has been our trusted spring manufacturer since 2019. Their diverse range of customized springs and wire forms has been pivotal in enhancing the performance of our industrial equipment. Their attention to detail and adherence to quality standards make them stand out.",
    },
  ];

  return (
    <section className="client_section layout_padding-bottom">
      <div className="container">
        <div className="heading_container heading_center">
          <h1
            style={{
              marginTop: "20px",
              textAlign: "center",
              color: "#0e3689;",
              fontWeight: "bold",
            }}
          >
            Our Clients
          </h1>
        </div>
        <div
          id="carouselExample2Controls"
          className="carousel slide"
          data-ride="carousel"
        >
          <div className="carousel-inner">
            {testimonials.map((testimonial, index) => (
              <div
                key={index}
                className={`carousel-item ${
                  index === activeIndex ? "active" : ""
                }`}
              >
                <div className="row">
                  <div className="col-md-11 col-lg-10 mx-auto">
                    <div className="box">
                      <div className="img-box">
                        <img src={testimonial.image} alt={testimonial.name} />
                      </div>
                      <div className="detail-box">
                        <div className="name">
                          <h6>{testimonial.name}</h6>
                        </div>
                        <p>{testimonial.text}</p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            ))}
          </div>
          <div
            className="carousel_btn-container"
            style={{ marginBottom: "20px" }}
          >
            <button className="carousel-control-prev" onClick={handlePrev}>
              <i className="fa fa-long-arrow-left" aria-hidden="true"></i>
              <span className="sr-only">Previous</span>
            </button>
            <button className="carousel-control-next" onClick={handleNext}>
              <i className="fa fa-long-arrow-right" aria-hidden="true"></i>
              <span className="sr-only">Next</span>
            </button>
          </div>
        </div>
      </div>
    </section>
  );
};

export default TestimonialSection;
