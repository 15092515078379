import React from "react";

const Cart = () => {
  return (
    <>
      <div style={{ marginTop: "20px" }}>
        <h1 className="about-us-heading">About Our Company</h1>
      </div>

      <div
        className="about-us-container"
        style={{
          width: "auto",
          // border: "2px solid red",
          display: "flex",
          justifyContent: "space-around",
          borderRadius: "50px",
        }}
      >
        <div
          style={{
            // border: "2px solid red",
            width: "auto",
            padding: "20px",
            // borderRadius: "40px",
            boxShadow: "rgba(0, 0, 0, 0.35) 0px 5px 15px",
          }}
        >
          {/* <div>
            <iframe
              width="1200"
              height="400"
              src="https://www.youtube.com/embed/GBO79ctIHzs"
              frameborder="0"
              allowfullscreen
              style={{
                borderRadius: "20px",
                boxShadow: "rgba(0, 0, 0, 0.35) 0px 5px 15px",
                marginBottom: "10px",
              }}
            ></iframe>
          </div> */}

          <div className="img-box">
            <img
              style={{ height: "auto",  width: "auto"}}
              src="https://images2.imgbox.com/a1/93/YHX9htBS_o.png"
              alt="iconic component"
            />
          </div>

          <div>
            <h4 className="sub_heading">About Us</h4>
            <p className="about-us-text">
              Iconic Components is managed by Mr. Keerthi C.R who has industry
              experience of more than seven years. Iconic Components was
              established in the year 2019 & we are ISO 9001-2015 Certified.{" "}
              <br />
              <br />
              We manufacture each & every form of spring for the different
              sector industries such as Automobile, Aerospace,
              Medical/Pharmaceutical, Electrical & Electronic, Agro based
              Industry, Construction equipment’s, Textile Machinery, SPM,
              Industrial Equipment’s and many more. <br />
              <br />
              We manufacture a different types of customized springs, wire forms
              and press components
            </p>
          </div>
          {/* <div>
            <h4 className="sub_heading">Who We Are?</h4>
            <p className="about-us-text">
              Iconic Component Works is managed by our managing director Mr.
              Keerthi C.R who has industry experience of more than two decades.
              We manufacture each & every form of spring for the different
              sector industries such as Automobile Industry, Engineering
              Industry, Pharmaceutical Industry, Civil Industry, Electrical &
              Electronic Industry, Rubber Industry, Steel Industry, Textile
              Industry, Agro based Industry, Industrial Equipments and many
              more. We are successful, fast-growing company, we have recently
              "doubled" the size of our facility with management personnel in
              place who have gained extensive product knowledge through team
              experience and stable workforce with years and reliable dedicated
              performance (our average employees has been with us for more than
              a decade). We manufacture a million types of springs and wire
              forms but we specialise in safety springs for Swimming Pool
              Industry where we make our global presence with flying colours. We
              are biggest/largest suppliers of pool cover springs & Hardware
              Accessories for Pool Cover Industry. Our Product line is broadly
              acknowledged for various salient attributes such as their high
              material strength, precise dimensions, perfect surface finish,
              etc. The Raw material used is from reputed suppliers like Tata
              Steel, Usha Martin, Viraj Steel, Panchmahal Steel etc and confirm
              to IS and international Specifications. From product release to
              manufacturing, pre and post inspections, Raw material and product
              traceability, we ensure total compliance with stringent Quality
              Management procedures, records and standard. With the constant
              efforts, high quality, timely delivery, effective teamwork, and
              after sales service, Iconic Component Works stands apart from the
              competition by serving all kinds of Springs & Sheet Metal
              Components with consistent quality and reliable services.
            </p>
          </div> */}
          <div>
            <h4 className="sub_heading">Infrastructure</h4>
            <p className="about-us-text">
              Iconic Components prioritizes quality in its manufacturing
              processes. Our infrastructure is well-equipped with the necessary
              machinery and regularly updated to maintain competitiveness. The
              facility is organized into efficient units, enhancing overall
              productivity. We emphasize the use of high-grade raw materials and
              modern machinery, aligning with industry standards. This ensures
              our products exhibit superior qualities such as sturdiness,
              durability, dimensional accuracy, and corrosion resistance.
              Rigorous quality examinations are conducted to eliminate
              manufacturing defects with precision.
            </p>
          </div>
          <div>
            <h4 className="sub_heading">MACHINERY</h4>
            <p className="about-us-text">
              We work on well organized machines as well as we work on "CNC
              Machines" to provide better quality product to fulfil the need of
              our client. We have years of experience in this field and well
              organized manufacturing plant & office with proper certifications.
              From many years, we are a successful supplier to our many clients
              in India.
            </p>
          </div>
          <div>
            <h4 className="sub_heading">Our Vision</h4>
            <p className="about-us-text">
              To be the key spring solution provider to our valuable customers
              all over the world.
              <ul>
                <li>To fulfill the clients requirement with our</li>
                <li>
                  quality and services. - To create our presence in global
                  market.
                </li>
              </ul>
            </p>
          </div>
          <div>
            <h4 className="sub_heading">Quality Policy</h4>
            <p className="about-us-text">
              Quality Policy For Continual improvement in Manufacture of Springs
              and Sheet Metal parts for Automobile, Electrical, Electronic,
              Engineering, and Civil Industry.
            </p>
          </div>
          <div>
            <h4 className="sub_heading">Applications</h4>
            <p className="about-us-text">
              We are Manufacturer of Springs for Automobile, Engineering, Civil,
              Power Energy & Pharmaceutical Industries.
            </p>
          </div>
        </div>
      </div>
    </>
  );
};

export default Cart;
