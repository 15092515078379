import React, { useState, useEffect } from "react";
import Product from "./Product";
import Styles from "../Styles/Product.module.css";
import About from "./About";
import TestimonialSection from "./TestimonialSection";
import GallerySection from "./GallerySection";

function Home() {
  const [currentImageIndex, setCurrentImageIndex] = useState(0);
  const imageUrls = [
    // " https://images2.imgbox.com/e6/5d/xmVxPqaI_o.png",
    "https://images2.imgbox.com/50/a1/s5kaWYxO_o.png",
    "https://images2.imgbox.com/e6/5d/xmVxPqaI_o.png",
    "https://images2.imgbox.com/8e/96/SpoSpF9E_o.png",
    "https://images2.imgbox.com/32/f9/mNFuN7C7_o.png",
    // "https://images2.imgbox.com/f8/02/2ZtuiibC_o.jpg",
    // "https://images2.imgbox.com/b3/f4/0ZWnkGXg_o.jpg",
    // "https://images2.imgbox.com/ac/07/xOjTpC32_o.jpg",
    // "https://images2.imgbox.com/0b/ce/6JLysQF5_o.jpg",
    // "https://images2.imgbox.com/9e/b9/wqIKceJd_o.jpg",
    // "https://images2.imgbox.com/81/34/NxMS0dJF_o.jpg",
  ];

  useEffect(() => {
    const interval = setInterval(() => {
      setCurrentImageIndex((prevIndex) => (prevIndex + 1) % imageUrls.length);
    }, 3000); // Change image every 5 seconds

    return () => {
      clearInterval(interval); // Clear the interval when the component unmounts
    };
  }, [imageUrls]);

  const currentImageUrl = imageUrls[currentImageIndex];

  return (
    <>
    <br />
      <section className="img-div">
        <div
          id="carouselExampleIndicators"
          className="carousel slide"
          data-bs-ride="carousel"
        >
          <div className="carousel-inner">
            {imageUrls.map((url, index) => (
              <div
                key={index}
                className={`carousel-item ${
                  index === currentImageIndex ? "active" : ""
                }`}
              >
                <img
                  src={url}
                  height="500px"
                  className="d-block w-100"
                  alt={`Slide ${index + 1}`}
                />
              </div>
            ))}
          </div>
        </div>
      </section>
      <About />
      <GallerySection />
      <TestimonialSection />

      {/* <Product /> */}
    </>
  );
}

export default Home;
