import React, { useState, useEffect } from "react";
import axios from "axios";
import { useNavigate } from "react-router-dom";

function ProductDropdown() {
  const [data, setData] = useState([]);
  const [selectedProduct, setSelectedProduct] = useState("");
  const navigate = useNavigate();

  useEffect(() => {
    async function getData() {
      try {
        let res = await axios.get(
          "https://iconic-component.onrender.com/product"
        );
        setData(res.data);
      } catch (error) {
        console.error("Error fetching data:", error);
      }
    }

    getData();
  }, [selectedProduct]);

  const handleDropdownChange = (e) => {
    const value = e.target.value;
    setSelectedProduct(value);

    // Find the selected product by name to get its ID
    const selectedProductObject = data.find(
      (product) => product.name === value
    );

    if (selectedProductObject) {
      // Navigate to the single product page with the selected product ID
      navigate(`/product/singleproduct/${selectedProductObject._id}`);
    }else {
      // Redirect to the home page
      navigate('/');
    }
  };

  return (
    <div
      style={{
        alignItems: "center",
        marginTop: "-10px",
        // marginLeft:"30px",
      }}
    >
      <select
        style={{
          marginTop: "20px",
          width: "auto",
          boxShadow: "rgba(0, 0, 0, 0.35) 0px 5px 15px",
        }}
        id="productDropdown"
        value={selectedProduct}
        onChange={(e) => handleDropdownChange(e)}
      >
        <option value="">Products</option>
        {data.map((product, index) => (
          <option key={index} value={product.name}>
            {product.name}
          </option>
        ))}
      </select>
    </div>
  );
}

export default ProductDropdown;
