import "./App.css";
import Footer from "./Components/Footer";
import Navbar from "./Components/Navbar";
import AllRouter from "./Routes/AllRouter";
import AOS from "aos";
import Kommunicate from "@kommunicate/kommunicate-chatbot-plugin";
import { Link } from "react-router-dom";


function App() {
  const whatsappIconStyle = {
    position: "fixed",
    width: "60px",
    height: "60px",
    bottom: "100px",
    right: "25px",
    backgroundColor: "#25d366",
    color: "#FFF",
    borderRadius: "50px",
    textAlign: "center",
    fontSize: "30px",
    boxShadow: "2px 2px 3px #999",
    zIndex: "100",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    cursor: "pointer",
    textDecoration: "none",
  };

  const indiamartIconStyle = {
    position: "fixed",
    width: "60px",
    height: "60px",
    bottom: "20px", // Adjust the positioning as needed
    right: "25px", // Adjust the positioning as needed
    backgroundColor: "#fff",
    color: "#000", // Adjust the text color as needed
    borderRadius: "50px",
    textAlign: "center",
    fontSize: "30px",
    boxShadow: "2px 2px 3px #999",
    zIndex: "50",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    cursor: "pointer",
    textDecoration: "none",
  };

  return (
    <div className="App">
      <a
        href="https://wa.me/+918123614636"
        className="whatsapp_float"
        target="_blank"
        rel="noopener noreferrer"
      >
        <i className="fa fa-whatsapp" style={whatsappIconStyle}></i>
      </a>

      <Link
        to="https://www.indiamart.com/iconic-components"
        className="indiamart_float"
        target="_blank"
        rel="noopener noreferrer"
      >
        <img
          src="https://companieslogo.com/img/orig/INDIAMART.NS-ecf147e0.png?t=1613453866"
          alt="IndiaMART"
          style={indiamartIconStyle}
        />
      </Link>

      <br />
      <Navbar />
      <AllRouter />
      <Footer />
    </div>
  );
}

export default App;