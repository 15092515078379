import React, { useContext } from "react";
import { ColorContext } from "../Context/ColorContext";
import Styles from "../Styles/Product.module.css";
import { Link } from "react-router-dom";
import Cart from "../Pages/Cart";

import {
  MDBFooter,
  MDBContainer,
  MDBRow,
  MDBCol,
  MDBIcon,
} from "mdb-react-ui-kit";
import { Center } from "@chakra-ui/react";

export default function Footer() {
  const { bg } = useContext(ColorContext);
  return (
    <MDBFooter
      style={{ backgroundColor: "#002147",
    width:"100vw" }}
      className="text-center text-lg-start text-muted"
      id={Styles.footer}
    >
      <section className="d-flex justify-content-center justify-content-lg-between p-4 border-bottom">
        <div className="me-5 d-none d-lg-block">
          <span style={{ color: "white", textDecoration: "none", width:"auto" }}>
            Get connected with us on social networks:
          </span>
        </div>

        <div>
          <a
            href="https://www.facebook.com/profile.php?id=61552731399316"
            className="me-4 text-reset"
          >
            <MDBIcon color="light" fab icon="facebook" />
          </a>
          <a href="https://twitter.com/Iconiccomp" className="me-4 text-reset">
            <MDBIcon color="light" fab icon="twitter" />
          </a>

          {/* <a
            href="https://priyankaphulmante98.github.io"
            className="me-4 text-reset"
          >
            <MDBIcon color="light" fab icon="instagram" />
          </a> */}
          <a
            href="https://www.linkedin.com/in/iconic-components-3b06bb298?
          miniProfileUrn=urn%3Ali%3Afs_miniProfile%3AACoAAEgBz3QBOaxnV36ombRjl1P1HB7GdGw6BvI&
          lipi=urn%3Ali%3Apage%3Ad_flagship3_search_srp_all%3Bi%2FWOqBM%2FS8izJ5pHGavqvA%3D%3D"
            className="me-4 text-reset"
          >
            <MDBIcon color="light" fab icon="linkedin" />
          </a>
        </div>
      </section>

      <section className="">
        <MDBContainer className="text-center text-md-start mt-5">
          <MDBRow className="mt-3">
            <MDBCol md="3" lg="4" xl="3" className="mx-auto mb-4">
              {/* <h4 className="text-uppercase fw-bold mb-4" style={{ color: "white" }} >
                <img 
                  src="https://images2.imgbox.com/0b/9e/RKEoADr3_o.jpg" width="70px"// Replace with the path to your company's logo image
                  alt="Company Logo"
                  className="me-3"
                />
                Iconic Components
              </h4>  */}
              <div style={{ display: "flex", alignItems: "center" }}>
                <img
                  src="https://images2.imgbox.com/0b/9e/RKEoADr3_o.jpg" // Replace with the path to your company's logo image
                  alt="Company Logo"
                  width="70px"
                  height="50px"
                  className="me-3"
                />
                <h4
                  className="text-uppercase fw-bold mb-4"
                  style={{ color: "white" }}
                >
                  Iconic Components
                </h4>
              </div>

              <p style={{ color: "white" }}>
              Iconic Components is one of the most trusted names in Manufacturing 
              High-Quality of customized springs in India. As one of the Leading
              Manufacturers and Suppliers of a Broad Range of Best Springs Manufacturer 
              in Bangalore.{" "}
              </p>
            </MDBCol>

            <MDBCol md="2" lg="2" xl="2" className="mx-auto mb-4">
              <h6
                style={{ color: "white" }}
                className="text-uppercase fw-bold mb-4"
              >
                Products
              </h6>
              <p>
                <a style={{ color: "white", textDecoration: "none" }}>
                  Compression Springs
                </a>
              </p>
              <p>
                <a style={{ color: "white", textDecoration: "none" }}>
                  Tension/Extension Springs
                </a>
              </p>
              <p>
                <a style={{ color: "white", textDecoration: "none" }}>
                  Torsion Springs
                </a>
              </p>
              <p>
                <a style={{ color: "white", textDecoration: "none" }}>
                  Wire forms
                </a>
              </p>
              {/* <p>
                <a style={{ color: "white", textDecoration: "none" }}>
                  Electrical & Electronics
                </a>
              </p>
              <p>
                <a style={{ color: "white", textDecoration: "none" }}>
                  Steel Industry
                </a>
              </p>
              <p>
                <a style={{ color: "white", textDecoration: "none" }}>
                  Engineering products
                </a>
              </p> */}
            </MDBCol>

            <MDBCol md="3" lg="2" xl="2" className="mx-auto mb-4">
              <h6
                style={{ color: "white" }}
                className="text-uppercase fw-bold mb-4"
              >
                Useful links
              </h6>
              <p>
                <a
                  href="https://drive.google.com/file/d/1egsnfa0g7KqXslrKK0td8PVfWub5hcn7/view?usp=drive_link"
                  style={{ color: "white", textDecoration: "none" }}
                >
                  Privacy and Policy
                </a>
              </p>
              <p>
                <Link
                  to="cart"
                  style={{ color: "white", textDecoration: "none" }}
                >
                  About Us
                </Link>
              </p>
              <p>
                <Link
                  to="/contact"
                  style={{ color: "white", textDecoration: "none" }}
                >
                  Contact Us
                </Link>
              </p>
              <p>
                <Link
                  to="/contact"
                  style={{ color: "white", textDecoration: "none" }}
                >
                  Support
                </Link>
              </p>
            </MDBCol>

            <MDBCol md="3" lg="2" xl="3" className="mx-auto mb-4">
              <h6
                style={{ color: "white" }}
                className="text-uppercase fw-bold mb-4"
              >
                Contact Us
              </h6>
              <p style={{ color: "white", textDecoration: "none" }}>
                <MDBIcon icon="home" /> No-75, 2nd Main Road 4th Cross Sai
                Mandir Road,Urban, Bengaluru-560079, Karnataka, India
              </p>
              <p style={{ color: "white", textDecoration: "none" }}>
                <MDBIcon icon="envelope" /> Info@iconiccomponents.com
              </p>
              <p style={{ color: "white", textDecoration: "none" }}>
                <MDBIcon icon="phone" /> +91 8123614636
              </p>
              <img
                src="https://images2.imgbox.com/01/c7/Dt0bNXlA_o.jpg"
                style={{ color: "white", textDecoration: "none" }}
              />
            </MDBCol>
          </MDBRow>
        </MDBContainer>
      </section>

      <div className="text-centre p-4" style={{ color: "white" }} align="Center">
        <p style={{ color: "white" }} align="centre">
        Copyright © 2023 Iconic Components | Powered by Aarav Ed Tech LLP
        </p>
      </div>
    </MDBFooter>
  );
}
