import React, { useContext } from "react";
import { useState } from "react";
import Styles from "../Styles/Navbar.module.css";
import { Link } from "react-router-dom";
import { AuthContext } from "../Context/AuthContext";
import { ColorContext } from "../Context/ColorContext";
import Dropdown from "../Pages/Dropdown";

function Navbar() {
  const { bg } = useContext(ColorContext);
  // let token = JSON.parse(localStorage.getItem('token')) || {}

  return (
    <>
      <div
        class="navbar-top"
        style={{
          width: "100vw",
          // paddingLeft:"40px",
          //  paddingRight:"20px",
          background: "#002D62",
          color: "#fff",
          // border: "2px solid red",
        }}
      >
        <div class="container">
          <div
            class="row "
            style={{
              width: "100vw",
              // background: "#002D62",

              // border: "2px solid red",
            }}
          >
            {/* <div class="col-md-8 text-md-left text-center">
              <ul
                style={{
                  // marginRight: "10px",
                  // border: "2px solid blue",
                  display: "flex",
                  justifyContent: "space-around",
                }}
              >
                <li>
                  <p>
                    <i class="fa fa-map-marker"></i>&nbsp;&nbsp;&nbsp;
                    Bengaluru-560079, Karnataka, India
                  </p>
                </li>
                <li>
                  <p>
                    <i class="fa fa-envelope-o"></i>
                    &nbsp;&nbsp; Info@iconiccomponents.com
                  </p>
                </li>
                <li>
                  <p>
                    <i class="fa fa-phone" style={{display:"flow"}}></i>
                    &nbsp;&nbsp;+91 8123614636
                  </p>
                </li>
              </ul>
            </div> */}
            <div class="col-md-8 text-md-left text-center">
              <ul
                style={{
                  marginRight: "10px",
                  display: "flex",
                  flexDirection: "column", // Display items vertically
                  alignItems: "flex-start", // Align items to the start (left)
                }}
              >
                <li>
                  <p>
                    <i class="fa fa-map-marker"></i>&nbsp;&nbsp;&nbsp;
                    Bengaluru-560079, Karnataka, India
                  </p>
                </li>
                <li>
                  <p>
                    <i class="fa fa-envelope-o"></i>
                    &nbsp;&nbsp; Info@iconiccomponents.com
                  </p>
                </li>
                <li>
                  <p>
                    <i class="fa fa-phone"></i>
                    &nbsp;&nbsp;+91 8123614636
                  </p>
                </li>
              </ul>
            </div>

            <div
              class="col-md-4"
              style={{
                // border: "2px solid red",
                display: "flex",
                justifyContent: "flex-end",
              }}
            >
              <ul class="topbar-right text-md-right text-center">
                <li class="social-area">
                  <a href="https://www.facebook.com/profile.php?id=61552731399316">
                    <i class="fa fa-facebook text-light" aria-hidden="true"></i>
                  </a>
                  <a href="https://twitter.com/Iconiccomp">
                    <i class="fa fa-twitter text-light" aria-hidden="true"></i>
                  </a>
                  {/* <a href="#">
                    <i class="fa fa-instagram" aria-hidden="true"></i>
                  </a> */}
                  <a
                    href="https://www.linkedin.com/in/iconic-components-3b06bb298?
          miniProfileUrn=urn%3Ali%3Afs_miniProfile%3AACoAAEgBz3QBOaxnV36ombRjl1P1HB7GdGw6BvI&
          lipi=urn%3Ali%3Apage%3Ad_flagship3_search_srp_all%3Bi%2FWOqBM%2FS8izJ5pHGavqvA%3D%3D"
                  >
                    <i class="fa fa-linkedin text-light" aria-hidden="true"></i>
                  </a>
                </li>
              </ul>
            </div>
          </div>
        </div>
      </div>

      <div id={Styles.Navbar}>
        <span
          style={{
            width: "400px",
            display: "flex",
            justifyContent: "space-around",
          }}
        >
          <span>
            <Link to="/">
              <div style={{ height: "60px", width: "100px" }}>
                <img
                  //  id={Styles.chitr}
                  src="https://images2.imgbox.com/40/70/fB0bNi7g_o.jpg"
                  alt="logo"
                />
              </div>
            </Link>
          </span>
          <span>
            <Dropdown />
          </span>
        </span>

        <div id={Styles.rightBox}>
          {/* <Link to="/login" style={{ fontSize: "15px" }}>
          <i style={{ fontSize: "15px" }} class="fa-solid fa-user"></i>
          &nbsp;&nbsp; LOGIN
        </Link> */}

          <Link to="/contact" style={{ fontSize: "15px" }}>
            <i style={{ fontSize: "15px" }} class="fa-solid fa-phone"></i>
            &nbsp;&nbsp; CONTACT
          </Link>

          {/* <Link to="/Certificate" style={{ fontSize: "15px" }}>
            <i style={{ fontSize: "15px" }} class="fa-solid fa-certificate"></i>
            &nbsp;&nbsp; CERTIFICATES
          </Link> */}

          <Link to="/about" style={{ fontSize: "15px" }}>
            <i
              style={{ fontSize: "15px" }}
              class="fa-solid fa-address-card"
            ></i>
            &nbsp;&nbsp; ABOUT
          </Link>
          <Link id={Styles.home} to="/" style={{ fontSize: "15px" }}>
            <i style={{ fontSize: "15px" }} class="fa-solid fa-house"></i>
            &nbsp; HOME
          </Link>
          {/* <Link to="/contact" style={{ fontSize: "15px" }}>
            <i style={{ fontSize: "15px" }} class="fa-solid fa-phone"></i>
            &nbsp;&nbsp; +91 8123614636
          </Link> */}
        </div>
      </div>
    </>
  );
}

export default Navbar;

{
  /* <Link to="/cart">
          <i style={{ fontSize: "25px" }} class="fa-solid fa-cart-plus"></i>
        </Link> */
}
{
  /* <Link to="/login">LOGIN</Link> */
}
{
  /* <Link to="/signup">SIGNUP</Link> */
}
{
  /* {token?.role === "admin" && <Link to="/admin">ADMIN</Link>} */
}
