import React from "react";
import { useState, useEffect } from "react";
import axios from "axios";
import Modal from "./Modal";
import Styles from "../Styles/AddProduct.module.css";

function AddProduct() {
  const [gdata, setgData] = useState([]);
  const [data, setData] = useState({
    img: "",
    name: "",
    description: "",
    price: "",
    category: "",
    manufacture_place: "",
    raw_material_grades: [""],
    production_range: [""],
    application: [""],
    subCategory: [""],
  });

  function handleChage(e) {
    const { name, value } = e.target;
    setData({ ...data, [name]: value });
  }

  function handleSubmit(e) {
    e.preventDefault();
    console.log(data);
    axios
      .post(`https://iconic-component.onrender.com/product`, data)
      .then((res) => {
        alert("product added sucessfully");
        getData();
      });
  }

  async function getData() {
    let res = await axios.get(`https://iconic-component.onrender.com/product`);
    console.log(res.data, "dataaaaaaaaaaaaaaaaaaaaa");
    setgData(res.data);
  }

  function handleDelete(id) {
    try {
      axios
        .delete(`https://iconic-component.onrender.com/product/${id}`)
        .then((res) => {
          alert("deleted");
          getData();
        });
    } catch (err) {
      console.log(err);
    }
  }

  useEffect(() => {
    getData();
  }, []);

  return (
    <>
      <form className={Styles.form} onSubmit={(e) => handleSubmit(e)}>
        <div
          className={Styles.logo}
          style={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <img
            src="https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcRVqhLeUXcpGhNJAJTl2SpCzD9qwiXNZV_XpA&usqp=CAU"
            alt="logo"
          />
        </div>
        <input
          type="url"
          placeholder="image"
          name="image"
          onChange={(e) => handleChage(e)}
          required="true"
        />
        <input
          type="text"
          placeholder="title"
          name="title"
          onChange={(e) => handleChage(e)}
          required="true"
        />
        <input
          type="text"
          placeholder="desc"
          name="desc"
          onChange={(e) => handleChage(e)}
          required="true"
        />
        <input
          type="text"
          placeholder="price"
          name="price"
          onChange={(e) => handleChage(e)}
          required="true"
        />
        <select name="category" onChange={(e) => handleChage(e)}>
          <option value="">Category</option>
          <option value="lipstick">Compression Springs</option>
          <option value="eyelinear">Eyelinear</option>
          <option value="cream">Cream</option>
          <option value="mositure">Mositure</option>
        </select>
        <input type="Submit" />
      </form>

      <div style={{ alignItems: "center" }}>
        {gdata?.map((e, i) => {
          return (
            <div
              key={i}
              style={{
                display: "flex",
                justifyContent: "space-around",
                // border: "2px solid red",
                alignItems: "center",
                marginBottom: "20px",
                height: "auto",
                backgroundColor: "white",
                width: "95%",
                textAlign: "center",
                padding: "30px",
                borderRadius: "20px",
                boxShadow: "rgba(0, 0, 0, 0.35) 0px 5px 15px",
                /* border:2px solid blue; */
              }}
            >
              <img
                src={e.img}
                alt="chitr"
                style={{ width: "100px", height: "100px" }}
              />
              <h3>{e.title}</h3>
              <h6>{e.name}</h6>
              {/* <h6>{e.category}</h6> */}
              {/* <p>{e.description}</p> */}
              {/* <h5>{e.price}</h5> */}
              <h5>{e.manufacture_place}</h5>
              <div style={{ display: "flex", justifyContent: "space-around" }}>
                <Modal dataa={e} />
                <button
                  type="button"
                  style={{ height: "40px", marginLeft: "10px" }}
                  className="btn btn-outline-primary "
                  onClick={() => handleDelete(e._id)}
                >
                  <span className="fa fa-sign-in me-1"></span> DELETE
                </button>
              </div>
            </div>
            // <>
            //   <div id={Styles.box} key={i}>
            //     <span
            //       style={{
            //         width: "450px",
            //         border: "3px solid blue",
            //         borderRadius: "10px",
            //         padding: "20px",
            //       }}
            //     >
            //       {" "}
            //       <h3>{e.name}</h3>
            //       <img
            //         src={e.img}
            //         alt="chi"
            //         style={{
            //           height: "170px",
            //           width: "400px",
            //         }}
            //       />
            //     </span>
            //     <span
            //       style={{
            //         textAlign: "left",
            //         padding: "15px",
            //       }}
            //     >
            //       {/* <h6>{e.category}</h6> */}
            //       <h5>{e.manufacture_place}</h5>
            //       <p>{e.description}</p>
            //       <div
            //         // to={`/`}
            //         className="btn btn-outline-primary"
            //       >
            //         ADD TO CART
            //       </div>
            //     </span>
            //   </div>

            //   <div id={Styles.box} key={i}>
            //     <span
            //       style={{
            //         textAlign: "left",
            //         padding: "15px",
            //       }}
            //     >
            //       <h6>{e.category}</h6>
            //       <h5>{e.manufacture_place}</h5>
            //       <p>{e.description}</p>
            //     </span>
            //     <span
            //       style={{
            //         width: "450px",
            //         // border: "3px solid blue",
            //         borderRadius: "10px",
            //         padding: "20px",
            //       }}
            //     >
            //       {" "}
            //       <h3>{e.name}</h3>
            //       <img
            //         src={e.img}
            //         alt="chi"
            //         style={{ height: "200px", width: "400px" }}
            //       />
            //     </span>
            //   </div>
            // </>
          );
        })}
      </div>
    </>
  );
}

export default AddProduct;
